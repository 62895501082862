import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/libraries/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h1>{props.pageContext.frontmatter.title}</h1>
        <p>{props.pageContext.frontmatter.description}</p>
        <hr></hr>
        <p>{`All of these libraries are first-party; we actively support them and hope they provide you with as much value as they provide us.`}</p>
        <h2>{`Libraries`}</h2>
        <h3><a parentName="h3" {...{
            "href": "/libraries/uid-library"
          }}>{`uid-library`}</a></h3>
        <p>{`This is used to generate unique `}<inlineCode parentName="p">{`id`}</inlineCode>{` values throughout React applications. This is most commonly used for
mapping form labels to form inputs, or mapping titles for accessibility. This library works when SSR and dynamically importing
application code.`}</p>
        <h3><a parentName="h3" {...{
            "href": "https://github.com/twilio-labs/svg-to-react"
          }}>{`SVG-to-React`}</a></h3>
        <p>{`SVG-to-React allows users to generate fully-formed and accessible React components from an SVG input.
The output will work similarly to the icons provided in Paste. This library was created to allow for
self-service when suggesting new Paste icons or when individual product needs differ from Paste. For more
information, see our `}<a parentName="p" {...{
            "href": "/icons/usage-guidelines"
          }}>{`icon guidelines`}</a>{` and our `}<a parentName="p" {...{
            "href": "/icons/how-to-add-an-icon"
          }}>{`how-to docs`}</a>{`.`}</p>
        <h3><a parentName="h3" {...{
            "href": "https://github.com/twilio-labs/sketch-twilio-ipsum/releases"
          }}>{`Sketch Twilio Ipsum`}</a></h3>
        <p>{`A `}<a parentName="p" {...{
            "href": "https://www.sketch.com/"
          }}>{`Sketch`}</a>{` plugin to help populate mockups with "real" data.
We believe there’s power in working with realistic and meaningful data as early as possible
in the design process because:`}</p>
        <ul>
          <li parentName="ul">{`Content can inform design decisions and help you convey your purpose`}</li>
          <li parentName="ul">{`Real data can reveal edge cases in your work that would otherwise be caught during development`}</li>
          <li parentName="ul">{`It’s fun!`}</li>
        </ul>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      